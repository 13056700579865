.error-placeholder {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: indianred;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  background-color: @main-6;

  &__message {
    margin: 20px;
  }
}
