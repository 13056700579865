.catalog {
  &__controls {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    input {
      margin: 0 10px;
      max-width: 300px;
    }
  }

  &__buttons {
    display: flex;
  }
}
