.customers {
  &__add-button {
    margin-top: 20px;
    align-self: flex-start;
  }

  &__search {
    margin: 20px 0;
    max-width: 400px;
  }

  &__buttons {
    display: flex;
  }
}
