.header {
  background: @blue;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  position: relative;
  min-height: 80px;

  &--login {
    display: none;
  }

  .lang-selector {
    margin-left: 10px;
  }

  &__email {
    font-size: 22px;
    color: white;
  }

  &__logout-button {
    margin-left: 10px;
    border-color: white;
    color: white;
    font-weight: normal !important;
    font-size: 22px !important;
    padding: 0 20px !important;

    &:hover {
      opacity: 0.3 !important;
      color: white;
      border-color: white;
    }
  }

  &__menu {
    margin: 5px auto 0 10px;
    display: none;
  }
}

@media (max-width: 769px) {
  .header {
    &__email {
      display: none;
    }

    &__logout-button {
      display: none;
    }

    .lang-selector {
      display: none;
    }

    &__menu {
      display: block;
    }

    &--login {
      .lang-selector {
        display: block;
      }
    }
  }
}
