.table {
  &--compact {
    td,
    th {
      padding: 12px;
    }

    th {
      font-size: 12px;
    }
  }

  &__row-total {
    font-weight: bold;
  }

  &__prewrap {
    white-space: pre-wrap;
  }

  .ant-input {
    min-width: 120px !important;

    /* Firefox */
    appearance: textfield;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &__buttons {
    display: flex;
  }
}
