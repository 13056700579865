.add-edit-order {
  &__checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__customer {
    display: flex;
    align-items: center;

    p {
      margin-right: 10px;
    }
  }

  &__checkbox {
    margin-left: 20px;

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
    }
  }

  &__row {
    display: flex;
    align-items: center;

    .ant-btn {
      margin-right: 10px;
    }
  }
}
