.orders {
  &__controls {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    > * {
      margin-right: 10px;
    }
  }

  &__select {
    min-width: 200px;
  }
}
