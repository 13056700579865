.payments {
  &__add_button {
    align-self: flex-start;
    margin: 20px 0;
  }

  &__cancel_button {
    margin-left: 10px;
  }
}
