.order-files {
  &__files {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  &__file-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__file {
    width: 150px;
    height: 150px;
    background-size: cover;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    border-radius: 20px;

    &--no-image {
      border: 5px solid @blue;
      width: 140px;
      height: 140px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-self: stretch;
  }

  &__button {
    align-self: flex-start;
  }
}
