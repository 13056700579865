.login {
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__form {
    width: 90vw;
    max-width: 500px;
    box-sizing: border-box;
    padding: 60px 100px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;

    h4 {
      text-align: center;
    }
  }

  &__error-message {
    color: @error-color;
    font-size: 18px;
    margin: 5px 0;
  }

  &__message {
    font-weight: bold;
  }

  &__validation {
    margin: 5px 0;

    .ant-checkbox-wrapper {
      margin-right: 5px;
    }

    .ant-checkbox,
    .ant-checkbox-wrapper,
    .ant-checkbox-input {
      cursor: unset;
    }
  }

  &__input,
  &__button {
    margin: 10px 0;
  }

  &__link {
    margin: 5px 0;
    font-size: 18px;
  }

  &__register-completed {
    font-size: 20px;
    margin: 0 20px;
  }

  &__checkbox-wrapper {
    white-space: pre-wrap;
  }

  &__legal-text {
    white-space: pre-wrap;
    overflow-y: auto;
    max-height: 60vh;
  }
}

@media (max-device-width: 560px) {
  .login__form {
    padding: 40px 20px;
  }
}
