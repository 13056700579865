.supplier-order-form {
  &__reference {
    width: 200px;
  }

  &__quantity {
    min-width: 30px;
  }

  &__price {
    min-width: 100px;
  }
}
