@green: #26BC78;

@yellowgreen: #AACE19;

@light-blue: #22CCE2;

@blue: #1C7CD5;

@purple: #D44AA6;

@orange: #E08309;

@main-1: #0E354A;

@main-2: #133A4F;

@main-3: #274B5E;

@main-4: #587583;

@main-5: #90A6B2;

@main-6: #F0F4F5;

@grey: #7A7A7A;

@import url("pages/Login/Login");
@import url("pages/ProductsToOrder/ProductsToOrder");
@import url("pages/AddEditOrder/AddEditOrder");
@import url("pages/Customers/Customers");
@import url("pages/Catalog/Catalog");
@import url("pages/SupplierOrders/SupplierOrders");
@import url("pages/Orders/Orders");
@import url("pages/Templates/Templates");
@import url("components/Header/Header");
@import url("components/ErrorPlaceholder/ErrorPlaceholder");
@import url("components/MainMenu/MainMenu");
@import url("components/SupplierOrderForm/SupplierOrderForm");
@import url("components/Payments/Payments");
@import url("components/OrderTable/OrderTable");
@import url("components/OrderFiles/OrderFiles");
@import url("modals/SupplierOrderModals/CreateSupplierOrderModal");
@import url("modals/CreateEditProductModal/CreateEditProductModal");

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/NotoSans-Regular.ttf");
}

@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: normal;
  src: url("./fonts/NotoSans-Italic.ttf");
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: bold;
  src: url("./fonts/NotoSans-Bold.ttf");
}

body {
  font-family: "Noto Sans", sans-serif;
  height: auto !important;
  min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: @main-6;
}

.dashboard-container {
  display: flex;
  flex: 1;
}

.content-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 40px 80px;
}

.buttons {
  display: flex;
  align-items: center;
  margin: 10px 0;

  button {
    margin-right: 10px;
  }
}

td.nowrap {
  white-space: nowrap;
}

.success,
.success > a {
  color: @success-color;
}

.warning,
.warning a {
  color: @warning-color;
}

.error,
.error > a {
  color: @error-color;
}

.loader-wrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: white;
    z-index: 10000;
  }
}

.warning-critical {
  font-size: @font-size-lg;
  color: @error-color;
}

.separator {
  height: 10px;
}

.draggable {
  cursor: pointer;
}

.yellowgreen {
  color: @yellowgreen;
}

.green {
  color: @green;
}

.lightblue {
  color: @blue;
}

.blue {
  color: @blue;
}

.purple {
  color: @purple;
}

.orange {
  color: @orange;
}

@media (max-width: 769px) {
  .error {
    font-size: 24px;
  }
}

@primary-color: #1C7CD5;@primary-1: tint(@primary-color, 90%);@primary-2: tint(@primary-color, 80%);@primary-5: tint(@primary-color, 20%);@link-color: #1C7CD5;@success-color: #26BC78;@warning-color: #E08309;@error-color: indianred;@normal-color: #F0F4F5;@font-size-base: 18px;@font-size-lg: 20px;@heading-color: black;@text-color: black;@text-color-secondary: black;@disabled-color: #7A7A7A;@border-radius-base: 6px;@border-color-base: black;@height-base: 50px;@border-width-base: 2px;@btn-font-weight: bold;@checkbox-size: 20px;@radio-size: 20px;@input-bg: #F0F4F5;@input-border-color: #F0F4F5;@padding-sm: 10px;@padding-md: 20px;@menu-dark-bg: #0E354A;@menu-dark-color: #90A6B2;@menu-dark-item-active-bg: #26BC78;@menu-icon-size: @font-size-lg;@menu-item-font-size: @font-size-lg;@menu-dark-selected-item-icon-color: white;@menu-dark-selected-item-text-color: white;@box-shadow-base: none;@card-radius: 10px;