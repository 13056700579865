.menu {
  background-color: @main-1;

  .ant-menu {
    .ant-menu-item {
      height: unset;
      padding: 10px 20px;
    }
  }

  &__close {
    display: none;
  }

  &__email {
    display: none;
  }
}

@media (max-width: 769px) {
  .menu {
    display: none;

    &__email {
      display: flex;
      justify-content: space-between;
      color: @main-5;
      padding: 20px;
      font-size: 20px;
    }

    &__logout-button {
      color: @main-5;
      border-color: @main-5;
      font-weight: normal;
    }

    &__text {
      display: inline;
    }

    &.opened {
      display: block;
      width: 100vw;
      max-width: unset;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      padding-top: 100px;
      background-color: @main-1;
      box-sizing: border-box;
      overflow-y: auto;
    }

    &__close {
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
