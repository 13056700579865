.supplier-orders {
  &__controls {
    display: flex;
    align-items: center;

    input {
      margin-left: 10px;
      max-width: 300px;
    }
  }

  &__select {
    margin-left: 10px;
    min-width: 200px;
  }

  &__payments {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: @blue;
  }
}
